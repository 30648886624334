<template>
  <div>
    <v-btn @click="click" color="green" class="white--text" small :loading="bindAdminLoading">綁定會員</v-btn>
  </div>
</template>

<script>
import listDataAction from "@/components/list/listDataAction/listDataAction.js";
export default {
  mixins: [listDataAction],
  data: () => ({
    bindAdminSelectionShow: false,
    bindAdminLoading: false,
    bindAdminConfirmLoading: false,
    authList: [
      { name: '主要管理員', type: 'MAIN' },
      { name: '共同管理員', type: 'COMMON' }
    ],
    memberList: [],
    
  }),
  methods: {
    async click() {
      try {
        this.bindAdminLoading = true
        const params = {
          page: 1,
          per: 9999,
          sort: {
            created_at: 'desc'
          }
        }
        const result = await this.$api.collection.memberApi.index(params)
        this.memberList = result.data
        this.$apopup.base({
          width: '600px',
          title: '綁定會員',
          bodySlot: () => import('./bindAdminPopup.vue'),
          authList: this.authList,
          memberList: this.memberList,
          applyLabel: '確認綁定',
          disabledApply: (formData) => {
            if(!formData) return true
            return !formData.authSelected || !formData.memberselected
          },
          applyCallback: async (formData) => {
            await this.bind(formData)
          },
        })
        this.bindAdminSelectionShow = true
      } catch (e) {
        console.log(e)
        this.bindAdminLoading = false
      } finally {
        this.bindAdminLoading = false
        this.bindAdminSelectionShow = false
      }
    },
    async bind(formData) {
      try {
        this.bindAdminConfirmLoading = true
        const identity = this.listKey == 'provider-list' ? 'provider' : 'store'
        const result = await this.$api.collection[`${identity}Api`].bindAdmin(this.row.id, formData.memberselected, formData.authSelected)
        this.$snotify.success(null, '綁定成功');
      } catch (e) {
        console.log(e)
        this.$snotify.error(null, '綁定失敗');
        this.bindAdminConfirmLoading = false
      } finally {
        this.bindAdminConfirmLoading = false
      }
    }
  },
};
</script>